.blue {
  background-color: #2872e0 !important;
}
.orange {
  background-color: #eb6b56 !important;
}
.greeny {
  background-color: #00a885 !important;
}
.purple {
  background-color: #553982 !important;
}
.yellow {
  background-color: #fac51c !important;
}
.grey {
  background-color: #75706b !important;
}
.lightGreen {
  background-color: #61bd6d !important;
}
.pupral {
  background-color: #9b59b6 !important;
}
.midnight {
  background-color: #2c3e50 !important;
}
.wisteria {
  background-color: #e67e22 !important;
}
.beliz {
  background-color: #75706b !important;
}
.neprus {
  background-color: #1abc9c !important;
}
.weibo {
  background-color: #df2029 !important;
}
.news {
  background-color: #ff6600 !important;
}
.face {
  background-color: #1877f2 !important;
}
.linked {
  background-color: #0a66c2 !important;
}
.vimeee {
  background-color: #1da1f2 !important;
}

.textblue {
  color: #2872e0 !important;
}
.textorange {
  color: #eb6b56 !important;
}
.textgreeny {
  color: #00a885 !important;
}
.textpurple {
  color: #553982 !important;
}
.textyellow {
  color: #fac51c !important;
}
.textgrey {
  color: #75706b !important;
}
.textlightGreen {
  color: #61bd6d !important;
}
.textpupral {
  color: #9b59b6 !important;
}
.textmidnight {
  color: #2c3e50 !important;
}
.textwisteria {
  color: #e67e22 !important;
}
.textbeliz {
  color: #75706b !important;
}
.textneprus {
  color: #1abc9c !important;
}
.textweibo {
  color: #df2029 !important;
}
.textnews {
  color: #ff6600 !important;
}
.textface {
  color: #1877f2 !important;
}
.textlinked {
  color: #0a66c2 !important;
}
.textvimeee {
  color: #1da1f2 !important;
}
.singleProduct {
  height: 200px;
  border: 2px solid grey;
}
.pointer{
  cursor: pointer !important;
}
.hoverblue:hover {
  box-shadow: 0px 0px 15px 3px #2872e0 !important;
}
.hoverorange:hover {
  box-shadow: 0px 0px 15px 3px #eb6b56 !important;
}
.hovergreeny:hover {
  box-shadow: 0px 0px 15px 3px #00a885 !important;
}
.hoverpurple:hover {
  box-shadow: 0px 0px 15px 3px #553982 !important;
}
.hoveryellow:hover {
  box-shadow: 0px 0px 15px 3px #fac51c !important;
}
.hovergrey:hover {
  box-shadow: 0px 0px 15px 3px #75706b !important;
}
.hoverlightGreen:hover {
  box-shadow: 0px 0px 15px 3px #61bd6d !important;
}
.hoverpupral:hover {
  box-shadow: 0px 0px 15px 3px #9b59b6 !important;
}
.hovermidnight:hover {
  box-shadow: 0px 0px 15px 3px #2c3e50 !important;
}
.hoverwisteria:hover {
  box-shadow: 0px 0px 15px 3px #e67e22 !important;
}
.hoverbeliz:hover {
  box-shadow: 0px 0px 15px 3px #75706b !important;
}
.hoverneprus:hover {
  box-shadow: 0px 0px 15px 3px #1abc9c !important;
}
.hoverweibo:hover {
  box-shadow: 0px 0px 15px 3px #df2029 !important;
}
.hovernews:hover {
  box-shadow: 0px 0px 15px 3px #ff6600 !important;
}
.hoverface:hover {
  box-shadow: 0px 0px 15px 3px #1877f2 !important;
}
.hoverlinked:hover {
  box-shadow: 0px 0px 15px 3px #0a66c2 !important;
}
.hovervimeee:hover {
  box-shadow: 0px 0px 15px 3px #1da1f2 !important;
}
.priceBar{
  position:absolute;
left:0;
right:0;
bottom: 0;
margin-left:auto;
margin-right:auto;
}

.borderblue {
  border-top:1px solid #2872e0 !important;
}
.borderorange {
  border-top:1px solid #eb6b56 !important;
}
.bordergreeny {
  border-top:1px solid #00a885 !important;
}
.borderpurple {
  border-top:1px solid #553982 !important;
}
.borderyellow {
  border-top:1px solid #fac51c !important;
}
.bordergrey {
  border-top:1px solid #75706b !important;
}
.borderlightGreen {
  border-top:1px solid #61bd6d !important;
}
.borderpupral {
  border-top:1px solid #9b59b6 !important;
}
.bordermidnight {
  border-top:1px solid #2c3e50 !important;
}
.borderwisteria {
  border-top:1px solid #e67e22 !important;
}
.borderbeliz {
  border-top:1px solid #75706b !important;
}
.borderneprus {
  border-top:1px solid #1abc9c !important;
}
.borderweibo {
  border-top:1px solid #df2029 !important;
}
.bordernews {
  border-top:1px solid #ff6600 !important;
}
.borderface {
  border-top:1px solid #1877f2 !important;
}
.borderlinked {
  border-top:1px solid #0a66c2 !important;
}
.bordervimeee {
  border-top:1px solid #1da1f2 !important;
}
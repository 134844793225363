.indicator {
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

.blueBorder{
  border:2px solid #1f69d8a1 !important;

}
.blue{
  background-color: #2872E0 !important;
}
.orange{
  background-color: #EB6B56 !important;
}
.blue{
  background-color: #2872E0 !important;
}
.greeny{
  background-color: #00A885 !important;
}
.purple{
  background-color: #553982 !important;
}
.yellow{
  background-color: #FAC51C !important;
}
.grey{
  background-color: #75706B !important;
}
.lightGreen{
  background-color: #61BD6D !important;
}

.headerCustom{
  border-radius: 30px;
  color: grey;
  cursor: pointer;
}
.headerCustom:hover{
  transition: all 0.5s ease-in-out;
  background-color: #5492ee !important;
  color: white !important;
}
.invert{
  fill: #ffffff;
  
}
.cardBtn{
  background: linear-gradient(180deg, rgba(44, 119, 231, 0.822) 25%, #165bc2 100%);
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
  height:50px;
  box-shadow: 0px 0px 2px 2px rgba(128, 128, 128, 0.575);

}
.shadowCurrent{
  box-shadow: 0px 0px 3px 2px rgba(128, 128, 128, 0.575);
}

.contact {
  top: 5vh;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  position: absolute;
  min-height: 70vh;
  z-index: 9999;
}

.floatingCart {
  /* position: fixed; */
  left: 5px;
  z-index: 9999 !important;
}
.cartContainer {
  top: 5vh;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  position: absolute;
}
.continueBtn:hover {
  background-color: #2872e0 !important;
  color: white !important;
}

.cartBtn:hover {
  transition: all 0.2s ease-in-out;
  /* transform: scale(1.1); */
  background-color: rgb(228, 226, 226);
  color: white;
}

/* width */
.itemContainer::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.itemContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.itemContainer::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.itemContainer::-webkit-scrollbar-thumb:hover {
  background: #555;
}
@media screen and (max-width: 850px) {
  .cartContainer {
    width: 90vw;
  }
}
@media screen and (max-width: 650px) {
  .cartContainer {
    width: 90vw;
  }
}

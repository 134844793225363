.searchIcon {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.searchInput{
    border:1px solid grey;
    border-right: none;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.searchInput::placeholder{
    font-weight: 500;
    padding-right: 15px !important;
}

.searchInput:focus{
    outline:none !important;
}
.blueBorderLight{
  border:2px solid #1f69d8a1 !important;
    
}

.searchResult{
  position:absolute;
left:0;
right:0;
top: 50%;
margin-left:auto;
margin-right:auto;
z-index: 9997 !important;
}
@media screen and (max-width: 1200px) {
  .searchResult {
    top: 55% !important;

  }
}

.listItem:hover{
background-color: #1877f2;
color: white !important;
z-index: 9999 !important;
}